<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              outlined
              v-model="selectedAdGroup"
              ref="selectedAdGroup"
              :items="adGroups"
              item-text="name"
              item-value="id"
              dense
              label="Prospects List"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="selectedStartDate"
              outlined
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="selectedEndDate"
              outlined
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
          >
            <v-select
              v-model="selectedAdMessage"
              ref="selectedAdMessage"
              :items="adMessages"
              item-text="title"
              item-value="id"
              outlined
              dense
              label="Choose Message"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
          >
            <p class="text--primary mt-n3 mb-2">
              Messaging Means
            </p>
            <v-radio-group
              row
              class="mt-0"
              hide-details
              ref="selectedMessageMeans"
              v-model="selectedMessageMeans"
            >
              <v-radio
                value="Email"
                label="Email"
              >
              </v-radio>
              <v-radio
                value="Whatsapp"
                label="Whatsapp"
              >
              </v-radio>
              <v-radio
                value="SMS"
                label="SMS"
              >
              </v-radio>
              <v-radio
                value="in_app_notify"
                label="In-App Notification"
              >
              </v-radio>
              <v-radio
                value="webpush"
                label="Webpush Notification"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-center">
        <v-btn
          color="primary"
          class="me-3 mt-3"
          @click="sendAdsSettings()"
        >
          Submit
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      selectedAdGroup: null,
      selectedAdMessage: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedMessageMeans: null,
      adGroups: [],
      adMessages: [],      
    }
  },
   
  mounted(){
      // executes these after the page has been mounted
      this.getAdGroups();
      this.getAdMessages();
      document.title = "Manager: Savings | Messaging Settings"
  },

  methods:{
    getAdGroups(){
      axios
        .get('/api/v1/manager/get/adgroups/Savings/')
        .then(response => {
          this.adGroups = response.data  // get the data and fill into resources 
          console.log(this.adGroups)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getAdMessages(){
      axios
        .get('/api/v1/manager/get/admessages/Savings/')
        .then(response => {
          this.adMessages = response.data  // get the data and fill into resources 
          console.log(this.adMessages)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async sendAdsSettings(){
      if(!this.selectedAdGroup){
          this.$store.commit('setSnackBarMessage', "Please select the advert messaging group.")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedAdGroup.focus()  // this causes a an auto fucos to the element
      }
      else if(!this.selectedAdMessage){
          this.$store.commit('setSnackBarMessage', "Please select the advert message.")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedAdMessage.focus()  // this causes a an auto fucos to the element
      }
      else if(!this.selectedMessageMeans){
        this.$store.commit('setSnackBarMessage', "Please select the advert messaging means.")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.selectedMessageMeans.focus()  // this causes a an auto fucos to the element
      }
      else {
        this.$router.push('/savings/marketing/send/ad_messages/'+this.selectedAdGroup+'/'+this.selectedAdMessage+'/'+this.selectedMessageMeans+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'')
      }
    },
  }
}
</script>
