<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ messagesList.length }}) List of Messages</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="10" class="text-xs font-weight-semibold" color="info darken-2"
        :to="{ name: 'savings.add_marketing_messages' }">
        <font-awesome-icon icon="fa-solid fa-plus" class="pe-1" />
        Add New Message
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="messagesList"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.title`]="{item}">
        <div class="d-flex flex-column" @click="editMessageInfo(item.id)" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.title }}</span>
          <small>Uploaded By: {{ item.uploader }}</small>
        </div>
      </template>
      <template #[`item.resource_size`]="{item}">
        {{ item.resource_size }}
      </template>
      <template #[`item.get_file`]="{item}">
        <a :href="item.get_file" target="_blank">
          <v-btn class="primary" elevation="6" small>
            <font-awesome-icon icon="fa-solid fa-download"/> 
            {{  item.size }}
          </v-btn>
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Subject', value: 'title' },
        { text: 'Message', value: 'content' },
        { text: 'Download', value: 'get_file' },
        { text: 'Link', value: 'link' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      messagesList: [],
      statusColor,
      search: '',

      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
 
  mounted(){
      // executes these after the page has been mounted
      this.getAdMessage();
      document.title = "Manager: Savings | List of Ad Resources"
  },

  methods:{
    getAdMessage(){
      axios
        .get('/api/v1/manager/get/messages_list/Savings/')
        .then(response => {
          this.messagesList = response.data  // get the data and fill into resources 
          console.log(this.messagesList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    editMessageInfo(info_id){
      let router = this.$router
      router.push('/savings/marketing/edit/messages/'+info_id)
    }
  },
}
</script>
